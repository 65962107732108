import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String, title: String }

  connect() {
    this.showLoadingCard()

    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html
        hookupJavascript()
      })
      .catch(error => console.error("Error loading content:", error))
  }

  showLoadingCard() {
    this.element.innerHTML = `
      <div class="mdl-card mdl-shadow--2dp" style="width:100%;min-height:50px">
        <div class="mdl-card__title">
          <h2 class="mdl-card__title-text">${this.titleValue || 'Loading...'}</h2>
        </div>
        <div class="mdl-card__supporting-text" style="display: flex; justify-content: center; align-items: center; height: 100px;">
          <div class="mdl-spinner mdl-js-spinner is-active"></div>
        </div>
      </div>
    `;
    componentHandler.upgradeDom(); // Ensures MDL components initialize correctly
  }
}
