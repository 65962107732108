import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    const workspaceId = this.element.dataset.workspaceId;
    const location = this.element.dataset.workspaceLocation;
    if (workspaceId && location==="main") {
      this.load(workspaceId, true, false, false);
    } else if (workspaceId && location==="sidebar") {
      this.loadOneJob(workspaceId, true, false, false);
    }
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;  
    // console.log("Connecting workspace controller...")
  }

  disconnect() {
    // console.log("Disconnecting workspace controller...")
  }

  rowClicked(event) {
    // event.preventDefault();

    // Remove "active" class from all list-group-item elements
    // const items = this.element.querySelectorAll('.list-group-item');
    const items = document.querySelectorAll('.list-group-item');

    items.forEach(item => {
      item.classList.remove('active');
    });

    // Retrieve data from the clicked item
    const workspaceId = event.currentTarget.dataset.workspaceId;
    const inbox = event.currentTarget.dataset.workspaceInbox !== undefined;
    const templates = event.currentTarget.dataset.workspaceTemplates !== undefined;
    this.load(workspaceId, inbox, templates, true);
  }

  showLoadingCard(selector, title = 'Loading...') {
    const cardHtml = `
      <div class="mdl-card mdl-shadow--2dp" style="width:100%;min-height:50px">
        <div class="mdl-card__title">
          <h2 class="mdl-card__title-text">${title}</h2>
        </div>
        <div class="mdl-card__supporting-text" style="display: flex; justify-content: center; align-items: center; height: 100px;">
          <div class="mdl-spinner mdl-js-spinner is-active"></div>
        </div>
      </div>
    `;
    $(selector).html(cardHtml);
    componentHandler.upgradeDom(); // Ensure MDL components initialize properly
  }

  // TODO: Deprecate this function - migrate to using loading_card_controller.js
  
  loadOneJob(workspaceId, inbox, templates, scroll) {
    this.showLoadingCard('#one-job-description', 'Content');
    $.ajax({
      url: '/jobs/' + workspaceId + '?partial=description',
      success: function(result) {
        $('#one-job-description').html(result)
        hookupJavascript();
    }});

    this.showLoadingCard('#one-job-collection-container', 'Inbox');
    $.ajax({
      url: '/collections/' + workspaceId + `?inbox=${inbox}&templates=${templates}`,
      success: function(result) {
        $('#one-job-collection-container').html(result)
        hookupJavascript();
    }});

    this.showLoadingCard('#one-job-item-header');
    $.ajax({
      url: '/collections/' + workspaceId + '/header',
      success: function(result) {
        $('#one-job-item-header').html(result)
        hookupJavascript();
      }
    })

    this.showLoadingCard('#one-job-notes-container', 'Notes');
    $.ajax({
      url: '/jobs/' + workspaceId + '/work_logs',
      success: function(result) {
        $('#one-job-notes-container').html(result)
        hookupJavascript();

        if (scroll && $(window).width() < 844) {
          $('#workspace')[0].scrollIntoView({behavior: 'smooth', alignto: 'top'});
        }
    }});

    this.showLoadingCard('#one-job-attachments-container', 'Attachments');
    $.ajax({
      url: '/jobs/' + workspaceId + '/attachments?skip_cta=true',
      success: function(result) {
        $('#one-job-attachments-container').html(result)
        hookupJavascript();
    }});

    this.showLoadingCard('#one-job-assistant-container', 'Your Assistant');
    $.ajax({
      url: '/collections/' + workspaceId + '/chat',
      success: function(result) {
        $('#one-job-assistant-container').html(result)
        hookupJavascript();
      }
    });

    this.showLoadingCard('#one-job-details-container', 'Details');
    $.ajax({
      url: '/jobs/' + workspaceId + '/details',
      success: function(result) {
        $('#one-job-details-container').html(result)
        hookupJavascript();
      }
    });
  }

  load(workspaceId, inbox, templates, scroll) {
    this.showLoadingCard('#workspace-description', 'Description');
    $.ajax({
      url: '/jobs/' + workspaceId + '?partial=description',
      success: function(result) {
        $('#workspace-description').html(result)
        hookupJavascript();
    }});

    this.showLoadingCard('#collection-container', 'Inbox');
    $.ajax({
      url: '/collections/' + workspaceId + `?inbox=${inbox}&templates=${templates}`,
      success: function(result) {
        $('#collection-container').html(result)
        hookupJavascript();
    }});

    this.showLoadingCard('#mainItemHeader');
    $.ajax({
      url: '/collections/' + workspaceId + '/header',
      success: function(result) {
        $('#mainItemHeader').html(result)
        hookupJavascript();
      }
    })

    this.showLoadingCard('#notes-container', 'Notes');
    $.ajax({
      url: '/jobs/' + workspaceId + '/work_logs',
      success: function(result) {
        $('#notes-container').html(result)
        hookupJavascript();

        if (scroll && $(window).width() < 844) {
          $('#workspace')[0].scrollIntoView({behavior: 'smooth', alignto: 'top'});
        }
    }});

    this.showLoadingCard('#attachments-container', 'Attachments');
    $.ajax({
      url: '/jobs/' + workspaceId + '/attachments?skip_cta=true',
      success: function(result) {
        $('#attachments-container').html(result)
        hookupJavascript();
    }});

    this.showLoadingCard('#assistant-chat', 'Your Assistant');
    $.ajax({
      url: '/collections/' + workspaceId + '/chat',
      success: function(result) {
        $('#assistant-chat').html(result)
        hookupJavascript();
      }
    });

    this.showLoadingCard('#details-container', 'Details');
    $.ajax({
      url: '/jobs/' + workspaceId + '/details',
      success: function(result) {
        $('#details-container').html(result)
        hookupJavascript();
      }
    });
  }

}
